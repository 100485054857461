function sos_preloader_overlay(caller) {
    return $(caller).closest('.sos-preloader-container').children('.sos-preloader-overlay');
}
function sos_ajax(event, opts) {
    var opts = opts || {};
    var toast_time = 5000;
    sos_preloader_overlay(this).show();
    $.post({
        url: $(this).attr('action'), 
        data: $(this).serialize(),
        context: this,
        dataType: 'json'
    }).done(function(data) {
        if (data['success']) {
            Materialize.toast(data['message'], toast_time);
            if (data['redirect']) window.location = data['redirect'];
            else sos_preloader_overlay(this).hide();
        }
        else {
            Materialize.toast(data['errors'].join('<br/>'), toast_time);
            sos_preloader_overlay(this).hide();
        }
        if (opts['done']) opts['done'].call(this, data);
    }).fail(function(data) {
        Materialize.toast('Server error. Please try again after refreshing the page.', toast_time);
        sos_preloader_overlay(this).hide();
        if (opts['fail']) opts['fail'].call(this, data);
    }).always(function(data) {
        if (opts['always']) opts['always'].call(this, data);
    });
    event.preventDefault();
}
$(function() {
    $(".button-collapse").sideNav({
        'menuWidth': 238,
        'fixedWidth': 600
    });
    $('.modal-trigger').leanModal();
    $('#form-contact').submit(function(event) {
        sos_ajax.call(this, event, {'done': function() {
            $('#contact').closeModal();
            this.reset();
        }});
    });
    $('.show-on-load').removeClass('show-on-load');
});